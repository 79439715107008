import React, { useContext } from 'react'
import { useLocation } from '@deal/router'
import { ExpertPresenceStatus } from '#src/generated/types'
import { getLeadsActionSummary } from '#src/app/utilities/grammerUtils'
import { getBroadcastCount, getFollowUpCount, getNeedsActionCount } from '#src/app/utilities/inbox'
import { Permission, useHasPermission } from '#src/app/utilities/permission'
import useBusinessUser from '#src/app/hooks/useBusinessUser'
import { InboxSnapshotContext } from '#src/app/context/InboxSnapshot'
import { LeadSearchFolderContext } from '#src/app/context/LeadSearchFolder'
import NavSectionWithCollapsibleContent from '#src/app/components/NavSectionWithCollapsibleContent'
import { ExpertLeadFolderGroupFragment } from '#src/app/components/Navigation/ExpertLeadFolderGroups/ExpertLeadFolderGroups.generated'
import NavSection from '../NavSection'
import NavFolderLink from '../NavFolderLink'
import BroadcastLeadFolder from '../Navigation/BroadcastLeadFolder'
import FollowUpLeadFolder from '../Navigation/FollowUpLeadFolder'
import { useExpertInboxSnapshotViewQuery } from '#src/app/components/InboxSnapshot/InboxSnapshot.generated'

interface Props {
  expertLeadFolderGroups: ExpertLeadFolderGroupFragment[]
  onClosed: () => void
}

const InboxFolderGroups: React.FC<React.PropsWithChildren<Props>> = ({
  expertLeadFolderGroups,
  onClosed
}) => {
  const location = useLocation()
  const leadSearchFolderContext = useContext(LeadSearchFolderContext)
  const businessUser = useBusinessUser()
  const onShift = businessUser.expertPresence.status === ExpertPresenceStatus.ON_SHIFT
  const { currentFollowUpLeadCount, setCurrentFollowUpLeadCount } = useContext(InboxSnapshotContext)
  const isExpertEscalationEnabled = businessUser.isExpert && businessUser.active

  const [canClaimBroadcastLead, canViewExpertMetrics] = useHasPermission(
    Permission.LEAD_ESCALATION_UPDATE,
    Permission.EXPERT_METRICS_READ
  )

  const { data: snapshotData } = useExpertInboxSnapshotViewQuery({
    variables: { expertId: businessUser.id },
    fetchPolicy: 'cache-and-network',
    skip: !businessUser.id || !canViewExpertMetrics,
    onCompleted: data => {
      const followUpCount = getFollowUpCount(data)
      currentFollowUpLeadCount !== followUpCount && setCurrentFollowUpLeadCount(followUpCount)
    }
  })

  const broadcastCount = getBroadcastCount(snapshotData)
  const needsActionCount = getNeedsActionCount(snapshotData)

  const showFollowUpLeadFolder =
    snapshotData?.expertInboxSnapshotView && isExpertEscalationEnabled && broadcastCount === 0

  return (
    <>
      {expertLeadFolderGroups.map(folderGroup => {
        return folderGroup.displayName === 'Inbox' ? (
          <React.Fragment key={folderGroup.type}>
            <NavSection sectionTitle={folderGroup.displayName}>
              {folderGroup.folders
                .filter(
                  folder => !folder.hideIfEmpty || (folder.totalCount && folder.totalCount > 0)
                )
                .map(folder => {
                  const subFolderCount = folder.subFolders[0]?.totalCount || 0
                  return (
                    <NavFolderLink
                      key={folder.id}
                      displayName={folder.displayName}
                      displayNameMessage={folder.description}
                      count={
                        folder.subFolders[0]?.totalCount ||
                        folder.totalCount ||
                        (onShift && folder.totalCountOnCurrentShift) ||
                        null
                      }
                      countMessage={
                        subFolderCount > 0
                          ? getLeadsActionSummary(subFolderCount)
                          : onShift &&
                            folder.totalCountOnCurrentShift &&
                            folder.displayName === 'No Replies'
                          ? '# of new non-responsive leads you have received on your current CAT'
                          : null
                      }
                      badgeCount={subFolderCount > 0}
                      to="/inbox"
                      isActive={
                        location.pathname.includes('/inbox') &&
                        leadSearchFolderContext.folder === folder.slug
                      }
                      onClick={() => {
                        leadSearchFolderContext.setFolder(folder.slug, null)
                        onClosed()
                      }}
                    />
                  )
                })}
            </NavSection>
            <NavSection sectionTitle="Lead Engagement">
              {isExpertEscalationEnabled && canClaimBroadcastLead && <BroadcastLeadFolder />}
              {showFollowUpLeadFolder && <FollowUpLeadFolder needsActionCount={needsActionCount} />}
            </NavSection>
          </React.Fragment>
        ) : (
          <NavSectionWithCollapsibleContent
            key={folderGroup.type}
            sectionTitle={folderGroup.displayName}
          >
            {folderGroup.folders.map(folder => (
              <NavFolderLink
                key={folder.id}
                displayName={folder.displayName}
                displayNameMessage={folder.description}
                count={folder.totalCount || (onShift && folder.totalCountOnCurrentShift) || null}
                countMessage={
                  onShift && folder.totalCountOnCurrentShift && folder.displayName === 'No Replies'
                    ? '# of new non-responsive leads you have received on your current CAT'
                    : null
                }
                to="/inbox"
                isActive={
                  location.pathname.includes('/inbox') &&
                  leadSearchFolderContext.folder === folder.slug
                }
                onClick={() => {
                  leadSearchFolderContext.setFolder(folder.slug, null)
                  onClosed()
                }}
              />
            ))}
          </NavSectionWithCollapsibleContent>
        )
      })}
    </>
  )
}

export default InboxFolderGroups
