import { useEffect, useRef } from 'react'
import { useShouldFireSetOnlineNudge, fireSetOnlineNudge } from './useShouldFireSetOnlineNudge'

export const useShowAppcuesOnlineNudge = () => {
  const shouldFireSetOnlineNudge = useShouldFireSetOnlineNudge()
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (shouldFireSetOnlineNudge) {
      intervalRef.current = setTimeout(() => {
        fireSetOnlineNudge()
        // Fire after 5 minutes
      }, 1000 * 60 * 5)
    } else {
      intervalRef.current && clearTimeout(intervalRef.current)
    }
  }, [shouldFireSetOnlineNudge])
}
