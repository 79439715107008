// @ts-nocheck
import type * as Types from '../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SuggestedMessageRevisionForSuggestedMessageEditorFragmentDoc } from './SuggestedMessageEditor/SuggestedMessageEditor.generated';
import { MediaFileFragmentDoc } from '../../fragments/mediaFile.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables = Types.Exact<{
  filter: Types.SuggestedMessageRevisionFilterInput;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery = { __typename: 'Query', suggestedMessageRevisionsIncludeSystemTypePagination: { __typename: 'SuggestedMessageRevisionsPagination', revisions: Array<{ __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, tags: Array<string>, starred?: boolean | null, type: Types.SuggestedMessageRevisionType, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null }> } };

export type SuggestedMessageTagsForManageQuickRepliesModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SuggestedMessageTagsForManageQuickRepliesModalQuery = { __typename: 'Query', suggestedMessageTags: { __typename: 'AttributeSchemaRevision', id: any, enumEntries: Array<{ __typename: 'AttributeEnumEntry', name: string, displayName: string, deprecated?: { __typename: 'AttributeEnumEntryDeprecated', at: any } | null }> } };

export type CreateNewSuggestedMessageByExpertMutationVariables = Types.Exact<{
  input: Types.CreateNewSuggestedMessageByExpertInput;
}>;


export type CreateNewSuggestedMessageByExpertMutation = { __typename: 'Mutation', createNewSuggestedMessageByExpert: { __typename: 'CreateNewSuggestedMessageByExpertPayload', revision: { __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, tags: Array<string>, starred?: boolean | null, type: Types.SuggestedMessageRevisionType, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null }, errorMessage?: { __typename: 'DefaultErrorMessage', errorMessage: string } | null } };

export type SuggestedMessageRevisionForManageQuickRepliesModalFragment = { __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, tags: Array<string>, starred?: boolean | null, type: Types.SuggestedMessageRevisionType, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null };

export const SuggestedMessageRevisionForManageQuickRepliesModalFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SuggestedMessageRevisionForManageQuickRepliesModal"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestedMessageRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SuggestedMessageRevisionForSuggestedMessageEditor"}}]}}]} as unknown as DocumentNode;
export const SuggestedMessageRevisionsPaginationForManageQuickRepliesModalDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SuggestedMessageRevisionsPaginationForManageQuickRepliesModal"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestedMessageRevisionFilterInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"offset"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suggestedMessageRevisionsIncludeSystemTypePagination"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}},{"kind":"Argument","name":{"kind":"Name","value":"offset"},"value":{"kind":"Variable","name":{"kind":"Name","value":"offset"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SuggestedMessageRevisionForManageQuickRepliesModal"}}]}}]}}]}},...SuggestedMessageRevisionForManageQuickRepliesModalFragmentDoc.definitions,...SuggestedMessageRevisionForSuggestedMessageEditorFragmentDoc.definitions,...MediaFileFragmentDoc.definitions]} as unknown as DocumentNode;
export type SuggestedMessageRevisionsPaginationForManageQuickRepliesModalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery, SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables>, 'query'> & ({ variables: SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SuggestedMessageRevisionsPaginationForManageQuickRepliesModalComponent = (props: SuggestedMessageRevisionsPaginationForManageQuickRepliesModalComponentProps) => (
      <ApolloReactComponents.Query<SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery, SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables> query={SuggestedMessageRevisionsPaginationForManageQuickRepliesModalDocument} {...props} />
    );
    

/**
 * __useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery__
 *
 * To run a query within a React component, call `useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery(baseOptions: Apollo.QueryHookOptions<SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery, SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery, SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables>(SuggestedMessageRevisionsPaginationForManageQuickRepliesModalDocument, options);
      }
export function useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery, SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery, SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables>(SuggestedMessageRevisionsPaginationForManageQuickRepliesModalDocument, options);
        }
export function useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery, SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery, SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables>(SuggestedMessageRevisionsPaginationForManageQuickRepliesModalDocument, options);
        }
export type SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryHookResult = ReturnType<typeof useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery>;
export type SuggestedMessageRevisionsPaginationForManageQuickRepliesModalLazyQueryHookResult = ReturnType<typeof useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalLazyQuery>;
export type SuggestedMessageRevisionsPaginationForManageQuickRepliesModalSuspenseQueryHookResult = ReturnType<typeof useSuggestedMessageRevisionsPaginationForManageQuickRepliesModalSuspenseQuery>;
export type SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryResult = Apollo.QueryResult<SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQuery, SuggestedMessageRevisionsPaginationForManageQuickRepliesModalQueryVariables>;
export const SuggestedMessageTagsForManageQuickRepliesModalDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SuggestedMessageTagsForManageQuickRepliesModal"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suggestedMessageTags"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"useCase"},"value":{"kind":"EnumValue","value":"LEAD"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enumEntries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"deprecated"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"at"}}]}},{"kind":"Field","name":{"kind":"Name","value":"displayName"}}]}}]}}]}}]} as unknown as DocumentNode;
export type SuggestedMessageTagsForManageQuickRepliesModalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SuggestedMessageTagsForManageQuickRepliesModalQuery, SuggestedMessageTagsForManageQuickRepliesModalQueryVariables>, 'query'>;

    export const SuggestedMessageTagsForManageQuickRepliesModalComponent = (props: SuggestedMessageTagsForManageQuickRepliesModalComponentProps) => (
      <ApolloReactComponents.Query<SuggestedMessageTagsForManageQuickRepliesModalQuery, SuggestedMessageTagsForManageQuickRepliesModalQueryVariables> query={SuggestedMessageTagsForManageQuickRepliesModalDocument} {...props} />
    );
    

/**
 * __useSuggestedMessageTagsForManageQuickRepliesModalQuery__
 *
 * To run a query within a React component, call `useSuggestedMessageTagsForManageQuickRepliesModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedMessageTagsForManageQuickRepliesModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedMessageTagsForManageQuickRepliesModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestedMessageTagsForManageQuickRepliesModalQuery(baseOptions?: Apollo.QueryHookOptions<SuggestedMessageTagsForManageQuickRepliesModalQuery, SuggestedMessageTagsForManageQuickRepliesModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedMessageTagsForManageQuickRepliesModalQuery, SuggestedMessageTagsForManageQuickRepliesModalQueryVariables>(SuggestedMessageTagsForManageQuickRepliesModalDocument, options);
      }
export function useSuggestedMessageTagsForManageQuickRepliesModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedMessageTagsForManageQuickRepliesModalQuery, SuggestedMessageTagsForManageQuickRepliesModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedMessageTagsForManageQuickRepliesModalQuery, SuggestedMessageTagsForManageQuickRepliesModalQueryVariables>(SuggestedMessageTagsForManageQuickRepliesModalDocument, options);
        }
export function useSuggestedMessageTagsForManageQuickRepliesModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SuggestedMessageTagsForManageQuickRepliesModalQuery, SuggestedMessageTagsForManageQuickRepliesModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SuggestedMessageTagsForManageQuickRepliesModalQuery, SuggestedMessageTagsForManageQuickRepliesModalQueryVariables>(SuggestedMessageTagsForManageQuickRepliesModalDocument, options);
        }
export type SuggestedMessageTagsForManageQuickRepliesModalQueryHookResult = ReturnType<typeof useSuggestedMessageTagsForManageQuickRepliesModalQuery>;
export type SuggestedMessageTagsForManageQuickRepliesModalLazyQueryHookResult = ReturnType<typeof useSuggestedMessageTagsForManageQuickRepliesModalLazyQuery>;
export type SuggestedMessageTagsForManageQuickRepliesModalSuspenseQueryHookResult = ReturnType<typeof useSuggestedMessageTagsForManageQuickRepliesModalSuspenseQuery>;
export type SuggestedMessageTagsForManageQuickRepliesModalQueryResult = Apollo.QueryResult<SuggestedMessageTagsForManageQuickRepliesModalQuery, SuggestedMessageTagsForManageQuickRepliesModalQueryVariables>;
export const CreateNewSuggestedMessageByExpertDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateNewSuggestedMessageByExpert"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateNewSuggestedMessageByExpertInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createNewSuggestedMessageByExpert"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SuggestedMessageRevisionForManageQuickRepliesModal"}}]}},{"kind":"Field","name":{"kind":"Name","value":"errorMessage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DefaultErrorMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]}}]}},...SuggestedMessageRevisionForManageQuickRepliesModalFragmentDoc.definitions,...SuggestedMessageRevisionForSuggestedMessageEditorFragmentDoc.definitions,...MediaFileFragmentDoc.definitions]} as unknown as DocumentNode;
export type CreateNewSuggestedMessageByExpertMutationFn = Apollo.MutationFunction<CreateNewSuggestedMessageByExpertMutation, CreateNewSuggestedMessageByExpertMutationVariables>;
export type CreateNewSuggestedMessageByExpertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNewSuggestedMessageByExpertMutation, CreateNewSuggestedMessageByExpertMutationVariables>, 'mutation'>;

    export const CreateNewSuggestedMessageByExpertComponent = (props: CreateNewSuggestedMessageByExpertComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNewSuggestedMessageByExpertMutation, CreateNewSuggestedMessageByExpertMutationVariables> mutation={CreateNewSuggestedMessageByExpertDocument} {...props} />
    );
    

/**
 * __useCreateNewSuggestedMessageByExpertMutation__
 *
 * To run a mutation, you first call `useCreateNewSuggestedMessageByExpertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewSuggestedMessageByExpertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewSuggestedMessageByExpertMutation, { data, loading, error }] = useCreateNewSuggestedMessageByExpertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewSuggestedMessageByExpertMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewSuggestedMessageByExpertMutation, CreateNewSuggestedMessageByExpertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewSuggestedMessageByExpertMutation, CreateNewSuggestedMessageByExpertMutationVariables>(CreateNewSuggestedMessageByExpertDocument, options);
      }
export type CreateNewSuggestedMessageByExpertMutationHookResult = ReturnType<typeof useCreateNewSuggestedMessageByExpertMutation>;
export type CreateNewSuggestedMessageByExpertMutationResult = Apollo.MutationResult<CreateNewSuggestedMessageByExpertMutation>;
export type CreateNewSuggestedMessageByExpertMutationOptions = Apollo.BaseMutationOptions<CreateNewSuggestedMessageByExpertMutation, CreateNewSuggestedMessageByExpertMutationVariables>;