// @ts-nocheck
import type * as Types from '../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { MediaFileFragmentDoc } from '../../../fragments/mediaFile.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SuggestedMessageRevisionForSuggestedMessageEditorFragment = { __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, tags: Array<string>, starred?: boolean | null, type: Types.SuggestedMessageRevisionType, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null };

export type SuggestedMessageEditorQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SuggestedMessageEditorQuery = { __typename: 'Query', quickReplyTemplateVariable?: { __typename: 'QuickReplyTemplateVariablePayload', variables?: Array<{ __typename: 'QuickReplyTemplateVariable', name: string, description: string, deprecated: boolean }> | null } | null };

export type UpdateSuggestedMessageByExpertMutationVariables = Types.Exact<{
  input: Types.UpdateSuggestedMessageByExpertInput;
}>;


export type UpdateSuggestedMessageByExpertMutation = { __typename: 'Mutation', updateSuggestedMessageByExpert: { __typename: 'UpdateSuggestedMessageByExpertPayload', revision: { __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, tags: Array<string>, starred?: boolean | null, type: Types.SuggestedMessageRevisionType, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null }, errorMessage?: { __typename: 'DefaultErrorMessage', errorMessage: string } | null } };

export type ArchiveSuggestedMessageByExpertMutationVariables = Types.Exact<{
  input: Types.ArchiveSuggestedMessageRevisionInput;
}>;


export type ArchiveSuggestedMessageByExpertMutation = { __typename: 'Mutation', archiveSuggestedMessageRevision: { __typename: 'ArchiveSuggestedMessageRevisionPayload', revision: { __typename: 'SuggestedMessageRevision', id: any, state: Types.SuggestedActionRevisionState } } };

export const SuggestedMessageRevisionForSuggestedMessageEditorFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SuggestedMessageRevisionForSuggestedMessageEditor"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestedMessageRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"tags"}},{"kind":"Field","name":{"kind":"Name","value":"starred"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"mediaFiles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MediaFile"}}]}}]}}]} as unknown as DocumentNode;
export const SuggestedMessageEditorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SuggestedMessageEditor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"quickReplyTemplateVariable"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"variables"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"deprecated"}}]}}]}}]}}]} as unknown as DocumentNode;
export type SuggestedMessageEditorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SuggestedMessageEditorQuery, SuggestedMessageEditorQueryVariables>, 'query'>;

    export const SuggestedMessageEditorComponent = (props: SuggestedMessageEditorComponentProps) => (
      <ApolloReactComponents.Query<SuggestedMessageEditorQuery, SuggestedMessageEditorQueryVariables> query={SuggestedMessageEditorDocument} {...props} />
    );
    

/**
 * __useSuggestedMessageEditorQuery__
 *
 * To run a query within a React component, call `useSuggestedMessageEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedMessageEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedMessageEditorQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestedMessageEditorQuery(baseOptions?: Apollo.QueryHookOptions<SuggestedMessageEditorQuery, SuggestedMessageEditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedMessageEditorQuery, SuggestedMessageEditorQueryVariables>(SuggestedMessageEditorDocument, options);
      }
export function useSuggestedMessageEditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedMessageEditorQuery, SuggestedMessageEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedMessageEditorQuery, SuggestedMessageEditorQueryVariables>(SuggestedMessageEditorDocument, options);
        }
export function useSuggestedMessageEditorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SuggestedMessageEditorQuery, SuggestedMessageEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SuggestedMessageEditorQuery, SuggestedMessageEditorQueryVariables>(SuggestedMessageEditorDocument, options);
        }
export type SuggestedMessageEditorQueryHookResult = ReturnType<typeof useSuggestedMessageEditorQuery>;
export type SuggestedMessageEditorLazyQueryHookResult = ReturnType<typeof useSuggestedMessageEditorLazyQuery>;
export type SuggestedMessageEditorSuspenseQueryHookResult = ReturnType<typeof useSuggestedMessageEditorSuspenseQuery>;
export type SuggestedMessageEditorQueryResult = Apollo.QueryResult<SuggestedMessageEditorQuery, SuggestedMessageEditorQueryVariables>;
export const UpdateSuggestedMessageByExpertDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateSuggestedMessageByExpert"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UpdateSuggestedMessageByExpertInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateSuggestedMessageByExpert"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SuggestedMessageRevisionForSuggestedMessageEditor"}}]}},{"kind":"Field","name":{"kind":"Name","value":"errorMessage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DefaultErrorMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errorMessage"}}]}}]}}]}}]}},...SuggestedMessageRevisionForSuggestedMessageEditorFragmentDoc.definitions,...MediaFileFragmentDoc.definitions]} as unknown as DocumentNode;
export type UpdateSuggestedMessageByExpertMutationFn = Apollo.MutationFunction<UpdateSuggestedMessageByExpertMutation, UpdateSuggestedMessageByExpertMutationVariables>;
export type UpdateSuggestedMessageByExpertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSuggestedMessageByExpertMutation, UpdateSuggestedMessageByExpertMutationVariables>, 'mutation'>;

    export const UpdateSuggestedMessageByExpertComponent = (props: UpdateSuggestedMessageByExpertComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSuggestedMessageByExpertMutation, UpdateSuggestedMessageByExpertMutationVariables> mutation={UpdateSuggestedMessageByExpertDocument} {...props} />
    );
    

/**
 * __useUpdateSuggestedMessageByExpertMutation__
 *
 * To run a mutation, you first call `useUpdateSuggestedMessageByExpertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSuggestedMessageByExpertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSuggestedMessageByExpertMutation, { data, loading, error }] = useUpdateSuggestedMessageByExpertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSuggestedMessageByExpertMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSuggestedMessageByExpertMutation, UpdateSuggestedMessageByExpertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSuggestedMessageByExpertMutation, UpdateSuggestedMessageByExpertMutationVariables>(UpdateSuggestedMessageByExpertDocument, options);
      }
export type UpdateSuggestedMessageByExpertMutationHookResult = ReturnType<typeof useUpdateSuggestedMessageByExpertMutation>;
export type UpdateSuggestedMessageByExpertMutationResult = Apollo.MutationResult<UpdateSuggestedMessageByExpertMutation>;
export type UpdateSuggestedMessageByExpertMutationOptions = Apollo.BaseMutationOptions<UpdateSuggestedMessageByExpertMutation, UpdateSuggestedMessageByExpertMutationVariables>;
export const ArchiveSuggestedMessageByExpertDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ArchiveSuggestedMessageByExpert"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ArchiveSuggestedMessageRevisionInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"archiveSuggestedMessageRevision"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"state"}}]}}]}}]}}]} as unknown as DocumentNode;
export type ArchiveSuggestedMessageByExpertMutationFn = Apollo.MutationFunction<ArchiveSuggestedMessageByExpertMutation, ArchiveSuggestedMessageByExpertMutationVariables>;
export type ArchiveSuggestedMessageByExpertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ArchiveSuggestedMessageByExpertMutation, ArchiveSuggestedMessageByExpertMutationVariables>, 'mutation'>;

    export const ArchiveSuggestedMessageByExpertComponent = (props: ArchiveSuggestedMessageByExpertComponentProps) => (
      <ApolloReactComponents.Mutation<ArchiveSuggestedMessageByExpertMutation, ArchiveSuggestedMessageByExpertMutationVariables> mutation={ArchiveSuggestedMessageByExpertDocument} {...props} />
    );
    

/**
 * __useArchiveSuggestedMessageByExpertMutation__
 *
 * To run a mutation, you first call `useArchiveSuggestedMessageByExpertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSuggestedMessageByExpertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSuggestedMessageByExpertMutation, { data, loading, error }] = useArchiveSuggestedMessageByExpertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveSuggestedMessageByExpertMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveSuggestedMessageByExpertMutation, ArchiveSuggestedMessageByExpertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveSuggestedMessageByExpertMutation, ArchiveSuggestedMessageByExpertMutationVariables>(ArchiveSuggestedMessageByExpertDocument, options);
      }
export type ArchiveSuggestedMessageByExpertMutationHookResult = ReturnType<typeof useArchiveSuggestedMessageByExpertMutation>;
export type ArchiveSuggestedMessageByExpertMutationResult = Apollo.MutationResult<ArchiveSuggestedMessageByExpertMutation>;
export type ArchiveSuggestedMessageByExpertMutationOptions = Apollo.BaseMutationOptions<ArchiveSuggestedMessageByExpertMutation, ArchiveSuggestedMessageByExpertMutationVariables>;