// @ts-nocheck
import type * as Types from '../../../../../generated/types';

import type { DocumentNode } from 'graphql';
import { SuggestedMessageRevisionForSuggestedMessageEditorFragmentDoc } from '../../../ManageQuickRepliesModal/SuggestedMessageEditor/SuggestedMessageEditor.generated';
import { MediaFileFragmentDoc } from '../../../../fragments/mediaFile.generated';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables = Types.Exact<{
  filter: Types.SuggestedMessageRevisionFilterInput;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type SuggestedMessageRevisionsPaginationForSuggestedMessageListQuery = { __typename: 'Query', suggestedMessageRevisionsIncludeSystemTypePagination: { __typename: 'SuggestedMessageRevisionsPagination', revisions: Array<{ __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, tags: Array<string>, starred?: boolean | null, type: Types.SuggestedMessageRevisionType, mediaFiles?: Array<{ __typename: 'MediaFile', id: any, url: string, mimeType: string, size: number, persisted: boolean }> | null }> } };

export type SuggestedMessageRevisionForSuggestedMessageListFragment = { __typename: 'SuggestedMessageRevision', id: any, title: string, message: string, tags: Array<string>, starred?: boolean | null, type: Types.SuggestedMessageRevisionType };

export type GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationVariables = Types.Exact<{
  input: Types.GenerateSingleLeadSuggestedMessageInput;
}>;


export type GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation = { __typename: 'Mutation', generateSingleLeadSuggestedMessage: { __typename: 'LeadSuggestedMessage', id: string, message: string } };

export const SuggestedMessageRevisionForSuggestedMessageListFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SuggestedMessageRevisionForSuggestedMessageList"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestedMessageRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"tags"}},{"kind":"Field","name":{"kind":"Name","value":"starred"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]} as unknown as DocumentNode;
export const SuggestedMessageRevisionsPaginationForSuggestedMessageListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SuggestedMessageRevisionsPaginationForSuggestedMessageList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filter"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SuggestedMessageRevisionFilterInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"limit"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"offset"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"suggestedMessageRevisionsIncludeSystemTypePagination"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filter"}}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}},{"kind":"Argument","name":{"kind":"Name","value":"offset"},"value":{"kind":"Variable","name":{"kind":"Name","value":"offset"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"revisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SuggestedMessageRevisionForSuggestedMessageEditor"}}]}}]}}]}},...SuggestedMessageRevisionForSuggestedMessageEditorFragmentDoc.definitions,...MediaFileFragmentDoc.definitions]} as unknown as DocumentNode;
export type SuggestedMessageRevisionsPaginationForSuggestedMessageListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SuggestedMessageRevisionsPaginationForSuggestedMessageListQuery, SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables>, 'query'> & ({ variables: SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SuggestedMessageRevisionsPaginationForSuggestedMessageListComponent = (props: SuggestedMessageRevisionsPaginationForSuggestedMessageListComponentProps) => (
      <ApolloReactComponents.Query<SuggestedMessageRevisionsPaginationForSuggestedMessageListQuery, SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables> query={SuggestedMessageRevisionsPaginationForSuggestedMessageListDocument} {...props} />
    );
    

/**
 * __useSuggestedMessageRevisionsPaginationForSuggestedMessageListQuery__
 *
 * To run a query within a React component, call `useSuggestedMessageRevisionsPaginationForSuggestedMessageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedMessageRevisionsPaginationForSuggestedMessageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedMessageRevisionsPaginationForSuggestedMessageListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSuggestedMessageRevisionsPaginationForSuggestedMessageListQuery(baseOptions: Apollo.QueryHookOptions<SuggestedMessageRevisionsPaginationForSuggestedMessageListQuery, SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedMessageRevisionsPaginationForSuggestedMessageListQuery, SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables>(SuggestedMessageRevisionsPaginationForSuggestedMessageListDocument, options);
      }
export function useSuggestedMessageRevisionsPaginationForSuggestedMessageListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedMessageRevisionsPaginationForSuggestedMessageListQuery, SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedMessageRevisionsPaginationForSuggestedMessageListQuery, SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables>(SuggestedMessageRevisionsPaginationForSuggestedMessageListDocument, options);
        }
export function useSuggestedMessageRevisionsPaginationForSuggestedMessageListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SuggestedMessageRevisionsPaginationForSuggestedMessageListQuery, SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SuggestedMessageRevisionsPaginationForSuggestedMessageListQuery, SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables>(SuggestedMessageRevisionsPaginationForSuggestedMessageListDocument, options);
        }
export type SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryHookResult = ReturnType<typeof useSuggestedMessageRevisionsPaginationForSuggestedMessageListQuery>;
export type SuggestedMessageRevisionsPaginationForSuggestedMessageListLazyQueryHookResult = ReturnType<typeof useSuggestedMessageRevisionsPaginationForSuggestedMessageListLazyQuery>;
export type SuggestedMessageRevisionsPaginationForSuggestedMessageListSuspenseQueryHookResult = ReturnType<typeof useSuggestedMessageRevisionsPaginationForSuggestedMessageListSuspenseQuery>;
export type SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryResult = Apollo.QueryResult<SuggestedMessageRevisionsPaginationForSuggestedMessageListQuery, SuggestedMessageRevisionsPaginationForSuggestedMessageListQueryVariables>;
export const GenerateSingleLeadSuggestedMessageForSuggestedMessageListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GenerateSingleLeadSuggestedMessageForSuggestedMessageList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GenerateSingleLeadSuggestedMessageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"generateSingleLeadSuggestedMessage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode;
export type GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationFn = Apollo.MutationFunction<GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation, GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationVariables>;
export type GenerateSingleLeadSuggestedMessageForSuggestedMessageListComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation, GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationVariables>, 'mutation'>;

    export const GenerateSingleLeadSuggestedMessageForSuggestedMessageListComponent = (props: GenerateSingleLeadSuggestedMessageForSuggestedMessageListComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation, GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationVariables> mutation={GenerateSingleLeadSuggestedMessageForSuggestedMessageListDocument} {...props} />
    );
    

/**
 * __useGenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation__
 *
 * To run a mutation, you first call `useGenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSingleLeadSuggestedMessageForSuggestedMessageListMutation, { data, loading, error }] = useGenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation(baseOptions?: Apollo.MutationHookOptions<GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation, GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation, GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationVariables>(GenerateSingleLeadSuggestedMessageForSuggestedMessageListDocument, options);
      }
export type GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationHookResult = ReturnType<typeof useGenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation>;
export type GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationResult = Apollo.MutationResult<GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation>;
export type GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationOptions = Apollo.BaseMutationOptions<GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutation, GenerateSingleLeadSuggestedMessageForSuggestedMessageListMutationVariables>;