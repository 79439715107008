import { useExperiment } from '@deal/experiment-js'
import useBusinessUser from './useBusinessUser'
import { ExpertPresenceStatus } from '#src/generated/types'
import { isMobileNativeOrBrowser } from '../services/mobile'
import { useUserAgentContext } from '../context/UserAgent'

const ELIGIBLE_STATUSES: ExpertPresenceStatus[] = [ExpertPresenceStatus.OFF_SHIFT]

export const useShouldFireSetOnlineNudge = () => {
  const userAgent = useUserAgentContext()
  const isMobile = isMobileNativeOrBrowser(userAgent)
  const { result } = useExperiment({
    experiment: 'biz-app-expert-availability-nudges',
    defaultTreatment: 'control'
  })
  const {
    expertPresence: { status }
  } = useBusinessUser()

  return result !== 'control' && !isMobile && ELIGIBLE_STATUSES.includes(status)
}

export const fireSetOnlineNudge = () => {
  if (window) {
    window.Appcues?.track('Show set online nudge')
  }
}
